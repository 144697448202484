import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { leadingZero } from 'utils/leadingZero';

import { Text, Box } from 'components';

import SizzleSlide from './SizzleSlide';

const Container = styled.section`
  width: 100%;
  height: 90vh;
  user-select: none;
  overflow: hidden;
  position: relative;
`;

const SizzleContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 5;
    display: block;
  }

  &:not(.one-slide) {
    &::before {
      left: 0;
      cursor: w-resize;
    }

    &::after {
      left: 50%;
      cursor: e-resize;
    }
  }
`;

const ProgressContainer = styled.div`
  position: absolute;
  bottom: 32px;
  left: 16px;
  right: 16px;
  height: 4px;
  display: flex;
  z-index: 4;
`;

const ProgressStep = styled.div`
  flex: 1 1 auto;
  margin: 0 1px;
  background: rgba(255, 255, 255, 0.3);
`;

const ProgressBar = styled(Box)`
  height: 100%;
`;

ProgressBar.defaultProps = {
  width: 0,
  bg: 'white'
};

const PageCounter = styled(Box)`
  position: absolute;
  width: 100%;
  bottom: 52px;
  z-index: 4;
`;

PageCounter.defaultProps = {
  color: 'white',
  px: 2,
  textAlign: ['center', 'right']
};

const Sizzle = ({ slides }) => {
  const [isPaused, setPaused] = useState(false);
  const [index, setIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const tapRef = useRef(null);
  const contentEl = useRef(null);

  // Set and remove listeners
  useEffect(() => {
    window.addEventListener('keyup', keyHandler);

    return () => {
      window.removeEventListener('keyup', keyHandler);
    };
  });

  const onMouseDown = e => {
    e.preventDefault();

    setPaused(true);

    const x = e.touches ? e.touches[0].clientX : e.pageX;

    tapRef.current = {
      x: x / window.innerWidth,
      timestamp: +new Date()
    };
  };

  const onMouseUp = e => {
    e.preventDefault();

    setPaused(false);

    const now = +new Date();

    if (tapRef.current && now - tapRef.current.timestamp < 200) {
      if (tapRef.current.x >= 0.5) {
        next();
      } else {
        prev();
      }
    }
  };

  // Handle key event, go to next or previous
  const keyHandler = e => {
    if (e.keyCode === 39) {
      next();
    } else if (e.keyCode === 37) {
      prev();
    }
  };

  // Next slide
  const next = () => {
    const newIndex = (index + 1) % slides.length;
    setProgress(0);
    setIndex(newIndex);
  };

  // Previous slide
  const prev = () => {
    const newIndex = index === 0 ? slides.length - 1 : index - 1;
    setProgress(0);
    setIndex(newIndex);
  };

  const updateProgress = progress => {
    if (progress === 1) {
      next();
    }

    setProgress(progress);
  };

  const getProgressBarWidth = i => {
    if (index === i) {
      return progress;
    }

    return index < i ? 0 : 1;
  };

  const hasOneSlide = slides.length === 1;

  // Render
  return (
    <Container
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
    >
      <SizzleContent
        ref={contentEl}
        className={hasOneSlide ? 'one-slide' : null}
      >
        {slides.map((slide, i) => (
          <SizzleSlide
            data={slide.primary}
            key={i}
            isActive={i === index}
            isUnique={hasOneSlide}
            isPaused={isPaused}
            updateProgress={updateProgress}
          ></SizzleSlide>
        ))}
      </SizzleContent>

      <ProgressContainer>
        {slides.map((slide, i) => {
          return (
            <ProgressStep data={slide.primary} key={i}>
              <ProgressBar width={getProgressBarWidth(i)} />
            </ProgressStep>
          );
        })}
      </ProgressContainer>

      {!hasOneSlide && (
        <PageCounter>
          <Text color="white">
            {leadingZero(index + 1, 2)} of {leadingZero(slides.length, 2)}
          </Text>
        </PageCounter>
      )}
    </Container>
  );
};

Sizzle.defaultProps = {
  slides: []
};

export default Sizzle;
