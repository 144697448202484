import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors } from 'styles/theme';

import { Box, Text, PostGrid } from 'components';

const MorePosts = styled(Box)`
  a {
    color: ${colors.blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    display: inline-block;
    transform: rotate(-90deg);
  }
`;

MorePosts.defaultProps = {
  pt: [3, 5],
  pb: [5, 7]
};

const LatestPosts = ({ posts }) => {
  return (
    <Box mt={5}>
      <Text size="3x" uppercase="true" color="brown" textAlign="center">
        Log
      </Text>

      <PostGrid posts={posts} />

      <MorePosts>
        <Text textAlign="center">
          <Link to="/log">
            See more in log <span>↓</span>
          </Link>
        </Text>
      </MorePosts>
    </Box>
  );
};

export default LatestPosts;
