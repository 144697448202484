import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { mediaQueries } from 'styles/theme';
import { Layout, SEO, Box } from 'components';

import {
  Sizzle,
  Introduction,
  FeaturedWork,
  WorkThemes,
  LatestPosts
} from 'components/homepage';

import { ProjectBasic, PostBasic } from 'fragments';

const Home = ({ data }) => {
  const page = data.prismic.allHomepages.edges.slice(0, 1).pop().node;
  const posts = data.prismic.allLog_items.edges;

  const metaData = {
    title: page.meta_title ? RichText.asText(page.meta_title) : null,
    description: page.meta_description
      ? RichText.asText(page.meta_description)
      : null
  };

  if (page.meta_image) {
    metaData.image = page.meta_image.url;
    metaData.meta = [
      {
        property: `og:image:width`,
        content: page.meta_image.dimensions.width
      },
      {
        property: `og:image:height`,
        content: page.meta_image.dimensions.height
      }
    ];
  }

  return (
    <Layout dark={true} footerExpanded={true}>
      <SEO {...metaData} />
      <Sizzle slides={page.body} />
      <Introduction content={page.introduction} />
      <FeaturedWork
        topCategory={page.top_category}
        productProjects={page.product_projects}
        communicationProjects={page.communication_projects}
      />

      <WorkThemes tags={page.interest_tag_preview} />

      <LatestPosts posts={posts} />
    </Layout>
  );
};

Home.fragments = [ProjectBasic, PostBasic];

export default Home;

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    prismic {
      allHomepages {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            body {
              ... on PRISMIC_HomepageBodySlide {
                type
                primary {
                  bg1
                  bg2
                  background_image
                  background_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 2880, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  video {
                    ... on PRISMIC__FileLink {
                      url
                      size
                    }
                  }
                  video_fallback_image
                  text
                  text_color
                  text_size
                  custom_text_size
                  flex_direction
                  justify_content
                  align_items
                  timer
                }
              }
            }
            introduction
            top_category
            product_projects {
              project {
                ...ProjectBasic
              }
            }
            communication_projects {
              project {
                ...ProjectBasic
              }
            }
            interest_tag_preview {
              tag {
                ... on PRISMIC_Interest_tag {
                  label
                  _meta {
                    uid
                    type
                  }
                }
              }
              color
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 2880, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      allLog_items(sortBy: date_DESC, first: 5) {
        edges {
          node {
            ...PostBasic
          }
        }
      }
    }
  }
`;
