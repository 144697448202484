import React, { useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';
import { color, space } from 'styled-system';

import { Grid, Box, Text } from 'components';

const Container = styled(Box)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

Container.defaultProps = {
  bg: 'black',
  py: [5, 7]
};

const Headline = styled(Text.withComponent('h2'))`
  text-align: center;
  z-index: 4;
  transition: opacity 0.4s ease-out;
`;

Headline.defaultProps = {
  color: 'pink',
  mb: [5, null, null, 7]
};

const ThemeGrid = styled(Grid)`
  z-index: 4;
`;

const ThemeList = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ThemeItem = styled(Link)`
  ${color}
  ${space}
  border-radius: 1em;
  cursor: pointer;
  text-decoration: none;
  flex: 0 0 auto;
  transition: opacity 0.2s ease-out, background-color 0.2s ease-out;
`;

ThemeItem.defaultProps = {
  bg: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  m: ['2px', '4px'],
  py: ['1em'],
  px: ['1.2em', '1.5em']
};

const ThemeItemAll = styled(ThemeItem)`
  &:hover {
    background: rgba(255, 255, 255, 0.15);
    transition: opacity 0.2s ease-out, background-color 0.1s ease-out;
  }
`;

const ThemeBackground = styled.div`
  ${color}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s ease-out;
`;

const WorkThemes = props => {
  const [theme, setTheme] = useState(null);

  return (
    <Container>
      <Headline size="3x" uppercase="true" opacity={theme ? 0 : 1}>
        Explore Common
        <br />
        Project Themes
      </Headline>

      <ThemeGrid>
        <Box gridColumn={['1 / -1', '2 / -2', '3 / -3']}>
          <ThemeList>
            <ThemeItemAll
              to="/work"
              opacity={theme === 'all' || !theme ? 1 : 0}
            >
              <Text>All</Text>
            </ThemeItemAll>
            {props.tags.map((tag, i) => {
              const t = tag.tag;

              return (
                <ThemeItem
                  to={`/work/#/interest/${t._meta.uid}`}
                  bg={theme === t._meta.uid ? tag.color : undefined}
                  opacity={theme === t._meta.uid || !theme ? 1 : 0}
                  onMouseEnter={() => {
                    setTheme(t._meta.uid);
                  }}
                  onMouseLeave={() => {
                    setTheme(null);
                  }}
                  key={i}
                >
                  <Text>{RichText.asText(t.label)}</Text>
                </ThemeItem>
              );
            })}
          </ThemeList>
        </Box>
      </ThemeGrid>

      {props.tags.map((tag, i) => {
        const t = tag.tag;

        return (
          <ThemeBackground key={i} opacity={theme === t._meta.uid ? 1 : 0}>
            {tag.image ? (
              <Img
                fluid={tag.imageSharp.childImageSharp.fluid}
                alt={RichText.asText(t.label)}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
            ) : null}
          </ThemeBackground>
        );
      })}
    </Container>
  );
};

export default WorkThemes;
