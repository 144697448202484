import React from 'react';
import styled from '@emotion/styled';
import { Grid, Box, Text } from 'components';
import { RichText } from 'prismic-reactjs';

import { mediaQueries, colors } from 'styles/theme';

const Container = styled(Box)`
  display: flex;
  min-height: 50vh;

  ${mediaQueries.sm} {
    border-bottom: 1px solid ${colors.gray};
  }

  ${mediaQueries.md} {
    min-height: 70vh;
  }

  ${mediaQueries.lg} {
    min-height: 100vh;
  }
`;

const Introduction = ({ content }) => {
  if (content.length) {
    return (
      <Container alignItems="center" py={7}>
        <Grid>
          <Box gridColumn={{ _: '2 / -2', md: '3 / -3', xl: '4 / -4' }}>
            <Text textAlign="center">{RichText.asText(content)}</Text>
          </Box>
        </Grid>
      </Container>
    );
  }

  return null;
};

export default Introduction;
