import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { background, flexbox } from 'styled-system';
import { RichText } from 'prismic-reactjs';
import gsap from 'gsap';

import { Text, Box } from 'components';

const CoverBox = styled(Box)`
  ${background}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
`;

const VideoCover = styled(CoverBox)`
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const SlideContent = styled(Text.withComponent('div'))`
  ${flexbox}
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

SlideContent.defaultProps = {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  textAlign: 'center',
  color: 'white',
  size: '2x',
  px: [2],
  py: ['100px']
};

const Slide = ({ data, isActive, updateProgress, isPaused, isUnique }) => {
  const videoRef = useRef();
  const tweenRef = useRef();
  const videoProgressRef = useRef();

  const [suspended, setSuspended] = useState(false);

  // Get video URL
  const videoUrl =
    data.video && data.video.__typename === 'PRISMIC__FileLink'
      ? data.video.url
      : null;

  // If isActive is changed, run this
  useEffect(() => {
    if (isActive) {
      activate();
    } else {
      deactivate();
    }

    return () => {
      deactivate();
    };
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      if (videoRef.current && !suspended) {
        if (isPaused) {
          videoRef.current.pause();
        } else {
          videoRef.current.play().catch(() => {
            setSuspended(true);
          });
        }
      } else if (tweenRef.current) {
        if (isPaused) {
          tweenRef.current.pause();
        } else {
          tweenRef.current.play();
        }
      }
    }
  }, [isPaused]);

  // Activate slide, play video, start progress tween
  const activate = () => {
    updateProgress(0);

    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current
        .play()
        .then(updateVideoProgress)
        .catch(() => {
          // If video can't play for some reason (power saving mode),
          // show fallback image
          setSuspended(true);
          startTween();
        });
    } else {
      startTween();
    }
  };

  const startTween = () => {
    if (tweenRef.current) {
      tweenRef.current.kill();
    }

    tweenRef.current = gsap.to({ t: 0 }, data.timer || 5, {
      t: 1,
      onUpdate: () => {
        updateProgress(tweenRef.current.progress());
      }
    });
  };

  // Deactivate slide, cancel any tweens
  const deactivate = () => {
    if (tweenRef.current) {
      tweenRef.current.kill();
    }

    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }

    if (videoProgressRef.current) {
      cancelAnimationFrame(videoProgressRef.current);
    }
  };

  const updateVideoProgress = v => {
    const progress = videoRef.current.currentTime / videoRef.current.duration;

    if (progress > 0) {
      updateProgress(progress);
    }

    if (progress < 1) {
      videoProgressRef.current = window.requestAnimationFrame(
        updateVideoProgress
      );
    }
  };

  /**
   * Return a background gradient or color
   * @param  {string} bg1 - first hex color
   * @param  {string} bg2 - second hex color for gradient
   * @returns {string} linear-gradient or hex
   */
  const getBackground = (bg1, bg2) => {
    return bg1 && bg2 ? `linear-gradient(to bottom, ${bg1}, ${bg2})` : bg1;
  };

  return (
    <CoverBox style={{ opacity: isActive ? 1 : 0 }}>
      <CoverBox background={getBackground(data.bg1, data.bg2)}>
        {data.background_image ? (
          <CoverBox
            style={{ backgroundImage: `url(${data.background_image.url})` }}
          />
        ) : null}
      </CoverBox>

      {videoUrl ? (
        <VideoCover>
          <video
            src={videoUrl}
            muted
            preload="auto"
            playsInline
            ref={videoRef}
            loop={isUnique}
          />

          {suspended && data.video_fallback_image ? (
            <CoverBox
              style={{
                backgroundImage: `url(${data.video_fallback_image.url})`
              }}
            />
          ) : null}
        </VideoCover>
      ) : null}

      <SlideContent
        flexDirection={data.flex_direction}
        justifyContent={data.justify_content}
        alignItems={data.align_items}
        color={data.text_color ? data.text_color : undefined}
        size={data.custom_text_size || data.text_size}
      >
        {data.text ? RichText.render(data.text) : null}
      </SlideContent>
    </CoverBox>
  );
};

export default Slide;
