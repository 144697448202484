import React, { useRef } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { mediaQueries, colors } from 'styles/theme';
import useAnimationFrame from 'utils/raf';

import { Grid, Box, Text } from 'components';
import { ProjectGridItem } from 'components/work';

import gsap from 'gsap';

const Container = Box.withComponent('section');

const CategoryHeader = styled(Box)`
  position: sticky;
  top: 0;
  height: 4rem;
  align-self: start;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: -1rem;
    width: calc(100% + 2rem);
    border-bottom: 1px solid ${colors.gray};
  }

  ${mediaQueries.sm} {
    height: 5rem;

    &::after {
      display: none;
    }
  }

  ${mediaQueries.md} {
    height: 6rem;
  }
`;

CategoryHeader.defaultProps = {
  bg: 'white',
  mb: [3, 5, null, null, 3],
  gridRow: ['auto', 1]
};

const ProjectContent = styled.div`
  @media screen and (max-width: 39.99em) {
    transform: translate(0, 0) !important;
  }
`;

const MoreWork = styled(Box)`
  a {
    color: ${colors.blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    display: inline-block;
    transform: rotate(-90deg);
  }
`;

MoreWork.defaultProps = {
  pt: [2, 7, 8],
  pb: [5, 5, 7]
};

const Project = ({ isTopCategory, project, index, communication }) => {
  const el = useRef(null);
  const contentEl = useRef(null);

  const orderIndex = (isTopCategory ? 0 : 1) + index * 2;

  const speeds = [-0.2, 0, -0.2, 0];

  useAnimationFrame(() => {
    const y = el.current.getBoundingClientRect().top;

    const diff = window.innerHeight - y;

    gsap.set(contentEl.current, {
      y: -diff * speeds[orderIndex]
    });
  });

  return (
    <Box
      ref={el}
      gridColumn={communication ? [1, 2] : 1}
      gridRow={['auto', orderIndex + 2]}
      mb={[5, 0]}
    >
      <ProjectContent ref={contentEl}>
        <ProjectGridItem project={project} featured={true} />
      </ProjectContent>
    </Box>
  );
};

const FeaturedWork = ({
  productProjects,
  communicationProjects,
  topCategory
}) => {
  let a1 = productProjects;
  let a2 = communicationProjects;

  if (topCategory === 'Communication') {
    [a1, a2] = [a2, a1];
  }

  return (
    <Container>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']} pb={[0, 0]}>
        <CategoryHeader>
          <Text>➊ Product</Text>
        </CategoryHeader>

        {productProjects.map((project, i) => {
          return (
            <Project
              project={project.project}
              index={i}
              isTopCategory={topCategory === 'Product'}
              key={i}
            />
          );
        })}

        <CategoryHeader>
          <Text>➋ Communication</Text>
        </CategoryHeader>

        {communicationProjects.map((project, i) => {
          return (
            <Project
              project={project.project}
              index={i}
              communication={true}
              isTopCategory={topCategory === 'Communication'}
              key={i}
            />
          );
        })}
      </Grid>

      <MoreWork>
        <Text textAlign="center">
          <Link to="/work">
            See more work <span>↓</span>
          </Link>
        </Text>
      </MoreWork>
    </Container>
  );
};

FeaturedWork.defaultProps = {
  productProjects: [],
  communicationProjects: [],
  topCategory: 'Product'
};

FeaturedWork.propTypes = {
  productProjects: PropTypes.array,
  communicationProjects: PropTypes.array,
  topCategory: PropTypes.string
};

export default FeaturedWork;
